import Alpine from "alpinejs";

export const SHOW_CONFIRMATION_MODAL = "show-confirmation-modal";
Alpine.data("modal", () => ({
  events: [SHOW_CONFIRMATION_MODAL],
  isVisible: false,
  title: "",
  callback: null,
  show(details) {
    const { title, callback } = details;
    this.title = title;
    this.callback = callback;
    this.isVisible = true;
  },
  hide() {
    this.title = "";
    this.callback = null;
    this.isVisible = false;
  },
  async confirm() {
    if (this.callback !== null) {
      await this.callback();
    }
    this.hide();
  },
  cancel() {
    this.hide();
  },
}));
