import Alpine from "alpinejs";
import Swiper, { Navigation } from "swiper";

Alpine.magic("swiper", (el) => {
  return {
    init(options) {
      const params = {
        modules: [Navigation],
        ...options,
      };
      new Swiper(el, params);
    },
  };
});
