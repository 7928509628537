import Alpine from "alpinejs";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { chatContextMenu } from "./chat-context-menu";
import { chatMentions } from "./chat-mentions";
import { chatGiphy } from "./chat-giphy";
import { chatReply } from "./chat-reply";
import { parseBody } from "./chats";
import { SHOW_CONFIRMATION_MODAL } from "../modal/modal";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo();

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

Alpine.data("chat", (el, currentApp, chat_id, chatInitialPost) => ({
  currentApp,
  chat_id,
  itemsContainer: el.querySelector(".chatpost-items"),
  textInput: el.querySelector("#id_body"),
  body: document.querySelector("body"),
  senderUUID: el.querySelector("input#id_sender").value,
  displayError: null,
  buttonDisabled: false,
  fileInput: null,
  ws: null,
  selectedFiles: [],
  parseBody,
  handleRemoveClicked() {
    this.hideMenuContext();
    this.$dispatch(SHOW_CONFIRMATION_MODAL, {
      title: "Are you sure you want to remove post?",
      callback: async () => {
        await this.removePost();
      },
    });
  },
  async removePost() {
    this.hideMenuContext();
    await sleep(10);
    const postId = this.contextMenuParams.post.id;
    postContent(`/chat/delpost/${postId}/`, {}).then(() => {
      document.querySelector(`#post_${postId}`).style.display = "none";
    });
  },
  handleReportClicked() {
    this.hideMenuContext();
    this.$dispatch(SHOW_CONFIRMATION_MODAL, {
      title:
        "Are you sure you want to report this post for Terms of Service violation?",
      callback: async () => {
        await this.reportPost();
      },
    });
  },
  async reportPost() {
    this.hideMenuContext();
    await sleep(5);
    const postId = this.contextMenuParams.post.id;
    postContent(`/chat/report-post/${postId}/`, {}).then(() => {
      //document.querySelector(`#post_${postId}`).style.display = "none";
    });
  },
  clearFiles() {
    this.fileInput.value = "";
    this.selectedFiles = [];
  },
  scrollIt() {
    // dirty hack for Safari on iOS
    el.querySelector("#add_file_dropdown_btn").click();
    el.querySelector("#add_file_dropdown_btn").click();
  },
  cleanupInput() {
    this.textInput.focus();
    this.buttonDisabled = false;
  },
  async postFormHandle(el) {
    if (!this.textInput.value && !this.selectedFiles.length) return;
    this.buttonDisabled = true;
    this.removeLeftOverMentions();
    await postForm(
      el,
      this.itemsContainer,
      function () {
        this.clearFiles();
        this.cleanupInput();
        this.scrollIt();
        this.replyToPost = null;
      }.bind(this),
      "prepend",
      function (response) {
        if (response.status == 429) {
          this.displayError = "You are posting too fast. Please wait a bit.";
        } else {
          this.displayError = "Something went wrong. Please try again later.";
        }
        setTimeout(() => {
          this.displayError = null;
          this.cleanupInput();
        }, 3000);
      }.bind(this),
      [
        { name: "refId", value: this.replyToPost?.id || null },
        {
          name: "mentions",
          value: JSON.stringify(this.mentionedUsers),
        },
      ]
    );
    this.textInput.setAttribute("style", "");
    this.mentionedUsers = [];
    this.contextMenuParams.post = null;
  },
  showSpinner: false,
  async loadMorePosts(start_id) {
    this.showSpinner = true;
    document.getElementById("intersect-trigger")?.remove();
    const formData = new FormData();
    formData.append("start_post_id", start_id);
    postContent(
      "/chat/more_posts/" + this.chat_id + "/",
      formData,
      this.itemsContainer,
      function () {
        this.showSpinner = false;
        this.scrollIt();
      }.bind(this),
      "append"
    );
  },
  wsInit() {
    const host = document.location.origin;
    this.ws = new WebSocket(
      host.replace("http", "ws") + "/ws/chat/" + this.chat_id + "/"
    );
    this.ws.onmessage = function (message) {
      const data = JSON.parse(message.data);
      if (data.type !== "chat.message") return; // handle only old "chat.message"
      if (data.sender === this.senderUUID) return; // skip if it's our message
      const parser = new DOMParser();
      const doc = parser.parseFromString(data.message, "text/html");
      const newEl = doc.querySelector("body").firstChild;
      this.itemsContainer.prepend(newEl);
      this.scrollIt();
    }.bind(this);
  },
  async init() {
    this.wsInit();
    // Wait for the images to load a bit
    this.textInput.focus();
    this.textInput.value = "";
    this.body.addEventListener("click", (e) => {
      if (e.target.offsetParent !== this.contextMenu) {
        this.hideMenuContext();
      }
    });
    if (chatInitialPost !== "None") {
      await this.scrollToPost(chatInitialPost);
    }
  },
  ...chatContextMenu,
  ...chatMentions(el),
  ...chatGiphy(el),
  ...chatReply,
}));

window.formatDate = function (datestring) {
  const date = new Date(datestring);
  const dateNow = new Date();
  if (
    date.getFullYear() === dateNow.getFullYear() &&
    date.getMonth() === dateNow.getMonth() &&
    date.getDate() === dateNow.getDate()
  ) {
    return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
  } else {
    return timeAgo.format(date);
  }
};
