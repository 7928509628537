import Alpine from "alpinejs";
import { formatAddressDisplay, csrftoken } from "../utils";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const fetchPlus = (url, options = {}, retries) =>
  fetch(url, options)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (retries > 0) {
        console.log("retry", retries);
        return sleep(2000).then(() => fetchPlus(url, options, retries - 1));
      }
      throw new Error(res.status);
    })
    .catch((error) => {
      console.error(error.message);
      if (retries > 0) {
        console.log("excretry", retries);
        return sleep(2000).then(() => fetchPlus(url, options, retries - 1));
      }
    });

Alpine.data("ethbtcmint", () => ({
  done: false,

  toAddress: null,
  value: null,
  txHash: null,

  show_payment: false,

  nfts: [],
  error: "",
  inProgress: false,
  progressText: "",
  successText: null,
  connectWallet: true,
  state: null,

  registerFunctionAbi: null,
  registerDelegateFunctionAbi: null,
  registerContractAddress: null,

  get txExplorerUrl() {
    if (this.txHash == null) return "";
    const { config } = this.$store;

    return `${config["etherscan"]}/tx/${this.txHash}`;
  },

  async setError(message) {
    this.nft.is_failed = true;
    this.nft.fail_message = message;
  },

  async updateNfts() {
    fetch("/collect/ethbtcmint/update/")
      .then((response) => response.json())
      .then((json) => {
        console.log("Json", json);
        this.nfts = json["nfts"];
        this.show_payment = json["show_payment"];
        if (json["should_update"]) setTimeout(this.updateNfts.bind(this), 4000);
      });
  },

  get isConnected() {
    return (
      (this.connectWallet && this.$store.web3.isConnected) ||
      !this.connectWallet
    );
  },

  async setup(params) {
    this.registerContractAddress = params.registerContractAddress;
    this.registerFunctionAbi = params.registerFunctionAbi;
    this.registerDelegateFunctionAbi = params.registerDelegateFunctionAbi;
    this.toAddress = params.toAddress;
    this.value = params.value;
    this.updateNfts();
  },

  async startPayment() {
    if (this.inProgress) return;

    this.inProgress = true;
    this.error = "";

    try {
      await this._runPayment();
    } catch (err) {
      this.error =
        err?.error?.message || err.reason || err.message || err.toString();
      return;
    } finally {
      this.inProgress = false;
    }
    this.done = true;
  },

  async _runPayment() {
    const { web3, config } = this.$store;
    const { mintChainId } = config;

    if (!web3.isConnected) {
      throw new Error("Please connect your wallet.");
    }

    if (web3.chainId !== mintChainId) {
      throw new Error("Please switch to Ethereum Mainnet first.");
    }

    const w3 = new Web3(web3.provider);
    this.progressText = "Waiting for transaction approval ...";
    const tx = w3.eth.sendTransaction({
      to: this.toAddress,
      value: this.value,
      from: web3.address,
    });

    tx.on("transactionHash", (hash) => {
      this.txHash = hash;
      this.progressText = "Processing ...";
      fetch("/collect/ethbtcmint/payment_set_hash/?tx=" + hash).then(
        (response) => {
          this.updateNfts();
        }
      );
    });

    await tx;
  },

  async runRegister(callArgs, address, bridgeId, value = 0) {
    console.log("runRegister", [
      this.registerFunctionAbi,
      callArgs,
      address,
      bridgeId,
      value,
    ]);
    const { web3, config } = this.$store;
    const { mintChainId } = config;

    if (!web3.isConnected) {
      throw new Error("Please connect your wallet.");
    }

    if (web3.chainId !== mintChainId) {
      throw new Error("Please switch to Ethereum Mainnet first.");
    }

    if (address && web3.address.toLowerCase() !== address.toLowerCase()) {
      await this.setError(
        `Please switch to ${formatAddressDisplay(address)} wallet.`
      );
      return;
    }

    const w3 = new Web3(web3.provider);
    const callFunctionAbi =
      address === ""
        ? this.registerDelegateFunctionAbi
        : this.registerFunctionAbi;

    const fnName = callFunctionAbi["name"];
    const contract = new w3.eth.Contract(
      [callFunctionAbi],
      this.registerContractAddress
    );
    const meth = contract.methods[fnName];

    console.log("stuff", [fnName, contract, meth]);

    this.progressText = "Waiting for transaction approval ...";
    const tx = meth
      .apply(null, callArgs)
      .send({
        from: address === "" ? web3.address : address,
        value: value,
        gas: 250000,
      });

    tx.on("transactionHash", (hash) => {
      this.txHash = hash;
      this.progressText = "Waiting for the transaction to be mined ...";
      fetch(
        `/collect/ethbtcmint/register_set_hash/${bridgeId}/?tx=` + hash
      ).then((response) => {
        this.updateNfts();
      });
    });

    await tx;
  },
}));
