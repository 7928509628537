import Alpine from "alpinejs";

Alpine.data("htmlFragment", () => ({
  loading: false,

  // load given url
  async load(url) {
    // do nothing
    if (url === false) return;
    const target = this.$refs.contents || this.$el;
    // make a GET request
    this.loading = true;
    try {
      const resp = await fetch(url);
      if (resp.ok) {
        target.innerHTML = await resp.text();
      }
    } finally {
      this.loading = false;
    }
  },

  clear() {
    const target = this.$refs.contents || this.$el;
    target.innerHTML = "";
  },
}));
