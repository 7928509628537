import Alpine from "alpinejs";
import * as DOMPurify from "dompurify";

const replaceMention = (body, at, mentions) => {
  const mentionEnd = body.indexOf(" ", at);
  const mentionMaybe = body.substring(
    at + 1,
    mentionEnd !== -1 ? mentionEnd : body.length
  );
  const foundMention = mentions.find(
    (mention) => mention.name === mentionMaybe
  );
  if (!foundMention) {
    return body;
  }
  const parsedMention =
    '<span class="fw-bold text-primary standard-background px-1" style="border-radius: 4px"><a href="/show/' +
    foundMention.id +
    '">@' +
    mentionMaybe +
    "</a></span>";
  return (
    body.substring(0, at) +
    parsedMention +
    body.substring(at + mentionMaybe.length + 1)
  );
};

export const parseBody = (body, mentions) => {
  if (mentions.length === 0) {
    return DOMPurify.sanitize(body).replace(/\r?\n/g, "<br />");
  }

  let bodyCopy = body.slice();
  const ats = [];
  for (let i = 0; i < bodyCopy.length; i++) {
    if (bodyCopy[i] === "@") {
      ats.push(i);
    }
  }
  [...ats].reverse().forEach((at) => {
    bodyCopy = replaceMention(bodyCopy, at, mentions);
  });
  return DOMPurify.sanitize(bodyCopy).replace(/\r?\n/g, "<br />");
};

const removeLineBreaks = (text) => {
  return text.replace(/<br \/>/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
};

Alpine.data("chats", () => ({
  parseBodyOnChatsList(body, mentions) {
    const parsedBody = parseBody(body, mentions);
    return removeLineBreaks(parsedBody);
  },
}));
