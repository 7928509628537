/* small utility helper for copying things to clipboard. */
import Alpine from "alpinejs";

Alpine.data("copyToClipboard", () => ({
  copied: false,

  async copyText(text) {
    try {
      await navigator.clipboard.writeText(text);
      this.copied = true;
    } catch (err) {
      alert(`Could not copy to the clipboard: ${err.message}`);
    }
  },
}));
