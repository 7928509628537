const contextMenu = document.querySelector("#chat-context-menu");

const isSafari =
  navigator.userAgent && navigator.userAgent.indexOf("AppleWebKit") !== -1;
export const chatContextMenu = {
  isLongTouch: false,
  tappedPost: null,
  contextMenu,
  contextMenuParams: {
    post: null,
  },
  hideMenuContext() {
    this.contextMenu.classList.remove("visible");
    this.tappedPost?.classList.remove("chat-post-tapped");
    this.tappedPost = null;
  },
  touchTimer: null,
  onLongTouch(event, post) {
    if (!isSafari || event.srcElement.nodeName == "IMG") {
      return;
    }
    this.isLongTouch = true;
    clearTimeout(this.touchTimer);
    this.touchTimer = null;
    const { clientX, clientY } = event.touches[0];
    this.contextMenuOpen(clientX + 60, clientY + 60, post);
  },
  onTouchStart(event, post) {
    if (!isSafari || event.srcElement.nodeName == "IMG") {
      return;
    }
    this.isLongTouch = false;
    if (this.touchTimer) {
      clearTimeout(this.touchTimer);
      this.touchTimer = null;
    }
    if (!this.touchTimer) {
      this.touchTimer = setTimeout(() => this.onLongTouch(event, post), 800);
    }
  },
  onTouchEnd(event) {
    if (!isSafari || event.srcElement.nodeName == "IMG") {
      return;
    }
    event.preventDefault();
    if (this.touchTimer) {
      clearTimeout(this.touchTimer);
      this.touchTimer = null;
    }
    if (!this.isLongTouch) {
      this.hideMenuContext();
    }
  },
  rightClick(event, post) {
    event.preventDefault();
    const { clientX, clientY } = event;
    this.contextMenuOpen(clientX, clientY, post);
  },
  contextMenuOpen(clientX, clientY, post) {
    this.hideMenuContext();
    const normalizePosition = (x, y) => {
      const { left, top } = this.itemsContainer.getBoundingClientRect();
      const bodyX = x - left;
      const bodyY = y - top;
      const outOfBoundsX =
        bodyX + this.contextMenu.clientWidth > this.itemsContainer.clientWidth;
      const outOfBoundsY =
        bodyY + this.contextMenu.clientHeight >
        this.itemsContainer.clientHeight;

      let normalizedX = x;
      let normalizedY = y;
      if (outOfBoundsX) {
        normalizedX =
          left + this.itemsContainer.clientWidth - this.contextMenu.clientWidth;
      }

      if (outOfBoundsY) {
        normalizedY =
          top +
          this.itemsContainer.clientHeight -
          this.contextMenu.clientHeight;
      }

      return { normalizedX, normalizedY };
    };
    this.contextMenuParams = {
      post,
    };
    const { normalizedY, normalizedX } = normalizePosition(clientX, clientY);
    this.contextMenu.style.top = `${normalizedY}px`;
    this.contextMenu.style.left = `${normalizedX}px`;

    setTimeout(() => {
      this.tappedPost = document.querySelector(`#post_${post.id}`);
      this.tappedPost.classList.add("chat-post-tapped");
      this.contextMenu.classList.add("visible");
    });
  },
};
