import Alpine from "alpinejs";
import { dispatch } from "alpinejs/src/utils/dispatch";

// note that if this receives a full <html> document it will
// extract it's body's contents.
const fromHtmlString = (html, { firstNode = false } = {}) => {
  const tpl = document.createElement("template");
  tpl.innerHTML = html;
  let result = tpl.content;
  if (firstNode) {
    if (result.childNodes.length > 1) {
      console.warn(
        "modal: more than one html node loaded; rest will be ignored"
      );
    }
    result = result.childNodes[0];
  }
  return result;
};

const sModal = Symbol("modal");

class ModalController {
  root = null;
  abort = null;
  // TODO there should be only one backdrop when multiple modals are open
  backdrop = null;

  /**
   * Finds the parent modal of the element.
   *
   * @param el {Element}
   * @return {ModalController | null}
   */
  static getModal(el) {
    let curr = el;
    while (curr != null) {
      if (curr[sModal] != null) return curr[sModal];
      curr = curr.parentNode;
    }
  }

  /** this is what will be exposed to Alpine */
  proxy = Alpine.reactive({
    // TODO maybe url access
    // TODO maybe upload progress / request progress
    close: (value) => {
      this.close(value);
    },
  });

  // TODO aborting previous load (use abort controller)
  /** opens a modal with loaded url */
  async loadUrl(url, options = {}) {
    const { method, csrfToken = null, json } = options;

    const init = {
      method,
      credentials: "same-origin",
      redirect: "follow",
      headers: {
        Accept: "text/html, application/json;q=0.9",
        "X-Requested-With": "XMLHttpRequest",
        ...(csrfToken && { "X-CSRFToken": csrfToken }),
      },
    };
    if (json != null) {
      init.body = JSON.stringify(json);
      init.headers["Content-Type"] = "application/json";
      if (!init.method) init.method = "post";
    }

    await this.request(url, init);
  }

  /**
   *
   * @param url {string}
   * @param init {RequestInit}
   * @return {Promise<void>}
   */
  async request(url, init) {
    // TODO calling abort() is not enough to eliminate race conditions,
    //  we still need to wait for the request() to be fully processed before
    //  we call another one (also consider multiple fast calls!).

    // TODO access to the request progress from the UI (via proxy).
    this.abort?.abort();
    this.abort = new AbortController();

    try {
      const resp = await fetch(url, { signal: this.abort.signal, ...init });

      if (!resp.ok) {
        // TODO: we need to inform the user somehow
        throw new Error("Request failed");
      }
      // TODO make sure it's HTML response
      // we assume that 204 means "all cool, close this modal"
      if (resp.status === 204) {
        this.close();
      } else {
        const html = await resp.text();
        this.setContents(html);
      }
    } catch (err) {
      // ignore aborts as they are expected and fine
      if (err.name !== "AbortError") throw err;
    }
  }

  setContents(html) {
    const el = fromHtmlString(html.trim(), { firstNode: true });
    this.setRoot(el);
  }

  setRoot(root) {
    const prev = this.root;
    if (prev != null) {
      prev.removeEventListener("submit", this.submitBubbled);
      prev.removeEventListener("click", this.clickBubbled);
      delete prev[sModal];

      root != null ? prev.replaceWith(root) : prev.remove();
    }

    if (root != null) {
      root[sModal] = this;
      if (prev == null) document.body.appendChild(root);
      root.addEventListener("submit", this.submitBubbled);
      root.addEventListener("click", this.clickBubbled);
    }

    this.root = root;
    this.updateBackdrop();
  }

  /* fn which can be triggered to close the modal */
  close(value = undefined) {
    const { root, abort } = this;
    abort?.abort();
    if (root == null) return;
    dispatch(root, "modal-close", { value });
    this.setRoot(null);
    // TODO make calling close() with value possible again
    // done(value);
  }

  updateBackdrop = () => {
    if (this.root != null && this.backdrop == null) {
      this.backdrop = fromHtmlString(
        `<div class="modal-backdrop fade show"></div>`,
        { firstNode: true }
      );
      document.body.appendChild(this.backdrop);
    } else if (this.root == null && this.backdrop != null) {
      this.backdrop.remove();
    }
  };

  /* intercepting form submission */
  submitBubbled = async (evt) => {
    // submit already canceled, bail out
    if (evt.defaultPrevented) return;
    const { submitter, target: form } = evt;
    const method = submitter?.getAttribute("formmethod") || form.method;
    const target = submitter?.getAttribute("formtarget") || form.target;
    let action = submitter?.getAttribute("formaction") || form.action;
    // "dialog" method, not sure if this even happens but why not handle it
    // setting "target" to a value (eg. target="_top" or target="_blank") means we don't want to handle this
    if (method === "dialog" || !!target) return;
    evt.preventDefault();
    const fd = new FormData(form);
    const init = {
      method,
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    if (method.toLowerCase() === "get") {
      // with action="GET" we can't send form data in body, but instead
      // we need to convert it to query params
      const url = new URL(action, location);

      const params = new URLSearchParams([
        ...Array.from(url.searchParams.entries()),
        ...Array.from(fd.entries()),
      ]);
      url.search = params.toString();
      action = url.toString();
    } else {
      init.body = fd;
    }

    await this.request(action, init);
  };

  /* link click interception and handling */
  clickBubbled = (evt) => {
    if (evt.defaultPrevented) return;
    const { target } = evt;
    const el = target.closest("a[href]");
    if (el == null || el.target || el.download) return;
    const href = el.href;
    evt.preventDefault();
    // ok, seems like we should follow this link so let's do it
    this.loadUrl(href, {});
  };
}

Alpine.magic("modal", (el) => {
  const modal = ModalController.getModal(el);

  return {
    openUrl(url, options) {
      const m = new ModalController();
      m.loadUrl(url, options);
      return m.proxy;
    },

    /**
     * For compatibility with chat-modal $modal api, not sure if we want to
     * implement tracking modal history */
    back() {
      modal?.close();
    },

    /**
     * Closes current modal.
     */
    close() {
      modal?.close();
    },

    /**
     * Navigates current modal to a new URL.
     */
    navigate(url, options) {
      modal?.loadUrl(url, options);
    },

    /**
     * Checks if we are in a modal.
     */
    inModal() {
      return modal != null;
    },
  };
});
