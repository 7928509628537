import { GiphyFetch } from "@giphy/js-fetch-api";
import { makeGiphyGrid } from "../../giphy";

export const chatGiphy = (el) => {
  return {
    gifQuery: el.querySelector("#gifquery"),
    gifCanvas: el.querySelector("#gifCanvas"),
    gifRemoveFunc: null,
    gifSelectorVisible: false,
    giphy: new GiphyFetch("HvoFbjeX3jUw9LxMuEWT3f9OOZpmtoSP"),

    toggleSelector() {
      this.gifSelectorVisible = !this.gifSelectorVisible;
      if (this.gifSelectorVisible) {
        const that = this;
        setTimeout(function () {
          that.gifQuery.focus();
        }, 50);
      } else {
        this.removeGiphy();
        this.gifQuery.value = "";
      }
    },

    async fetchGifs(offset) {
      // use whatever end point you want,
      // but be sure to pass offset to paginate correctly
      try {
        return await this.giphy.search(this.gifQuery.value, {
          offset,
          limit: 25,
        });
      } catch (e) {
        console.log("Error", e);
      }
    },

    removeGiphy() {
      if (this.gifRemoveFunc) {
        this.gifRemoveFunc();
        this.gifRemoveFunc = null;
      }
      this.gifCanvas.innerHTML = "";
    },

    async onGifClick(gif, e) {
      e.preventDefault();
      const fd = new FormData();
      fd.set("gif_url", gif.images.fixed_height.url);
      fd.set("sender", this.senderUUID);
      await postContent(
        "/chat/gif_post_ajax/" + this.chat_id + "/",
        fd,
        this.itemsContainer,
        this.scrollIt.bind(this),
        "prepend"
      );
      this.toggleSelector();
    },

    gifSearch() {
      this.removeGiphy();
      const query = this.gifQuery.value;
      if (query.length < 1) return;
      this.gifRemoveFunc = makeGiphyGrid(
        this.gifCanvas,
        this.fetchGifs.bind(this),
        this.onGifClick.bind(this)
      ).remove;
    },
  };
};
