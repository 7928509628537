import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import "./alpine/modal";
import "./alpine/copyToClipboard";
import "./alpine/web3";
import "./alpine/execWeb3Call";
import "./alpine/sendEth";
import "./alpine/config";
import "./alpine/htmlFragment";
import "./alpine/fetcher";
import "./alpine/loginComponent";
import "./alpine/swiper";
import "./alpine/tokenProof";
import "./alpine/chat/chat";
import "./alpine/chat/chats";
import "./alpine/modal/modal";
import "./alpine/siteRoles";
import "./alpine/nftGate";

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.start();
