import Alpine from "alpinejs";
import web3store from "../stores/web3-store";
import { jsonPost } from "../utils";
import { formatAddressDisplay } from "../utils";
import { signMessage } from "@wagmi/core";

Alpine.data("loginComponent", (props) => ({
  done: false,
  error: "",
  inProgress: false,

  get isConnected() {
    return this.$store.web3.isConnected;
  },

  get isInvalidAddress() {
    return (
      props.expectedWallet &&
      this.address != null &&
      this.address.toLowerCase() !== props.expectedWallet.toLowerCase()
    );
  },

  get expectedWalletShort() {
    return formatAddressDisplay(props.expectedWallet);
  },

  get address() {
    return this.$store.web3.address;
  },

  async _login() {
    const address = web3store.address;
    if (this.isInvalidAddress) {
      throw new Error(
        `You need to sign in with ${formatAddressDisplay(
          props.expectedWallet
        )} wallet.`
      );
    }

    const { type, data: { message } = {} } = await jsonPost(
      "/auth/login/nonce/",
      { address }
    );

    if (type !== "ok") throw new Error("Invalid request");

    const signature = await signMessage({ message: message });
    /*
    const signature = await web3store.backend.request({
      method: "personal_sign",
      params: [message, address.toLowerCase()],
    });
    */
    const result = await jsonPost("/auth/login/verify/", {
      signature,
      address,
      return_url: props.returnUrl,
    });

    // at the end
    this.navigate(this.return_url || result.data.returnUrl || props.returnUrl);
  },

  async login() {
    if (this.inProgress) return;

    this.inProgress = true;
    this.error = "";

    try {
      await this._login();
    } catch (err) {
      this.error =
        err?.error?.message || err.reason || err.message || err.toString();
      console.error(err);
    } finally {
      this.inProgress = false;
    }
  },

  navigate(url) {
    if (this.$modal.inModal() && url) {
      this.$modal.navigate(url);
    } else {
      window.location = url || "/";
    }
  },

  delegateLogin() {
    this.return_url = "/auth/login/delegate-cash/";
    this.toggleModal();
  },

  walletLogin() {
    this.return_url = null;
    this.toggleModal();
  },

  toggleModal() {
    this.isConnected
      ? this.$store.web3.disconnect()
      : this.$store.web3.connect();
  },
}));
