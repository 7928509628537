import Alpine from "alpinejs";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

Alpine.data("tokenProof", (readyProbe) => ({
  done: false,
  error: "",
  inProgress: false,
  authenticated: false,

  async init() {
    if (this.inProgress) return;

    this.inProgress = true;
    this.error = "";

    for (let i = 0; i < 150; i++) {
      await sleep(2000);
      if (!this.inProgress) {
        break;
      }
      const resp = await fetch(readyProbe, { method: "GET" });
      if (resp.ok) {
        const data = await resp.json();
        if (data.status != "pending") {
          this.inProgress = false;
          if (data.status == "authenticated") {
            this.authenticated = true;
          }
          break;
        }
      }
    }

    this.done = true;
  },
  destroy() {
    this.inProgress = false;
  },
}));
