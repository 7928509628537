const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
export const chatMentions = (el) => {
  return {
    autocompleteVisible: false,
    autocompleteInput: "",
    autocompleteUsers: [],
    autocompleteSelection: -1,
    autocompleteCurrentUsers: [],
    mentionedUsers: [],
    fetchingUsers: false,
    removeLeftOverMentions() {
      const { value } = this.textInput;
      const indexesToRemove = [];
      this.mentionedUsers.forEach((mentionedUser, index) => {
        if (!value.includes(mentionedUser.name)) {
          indexesToRemove.push(index);
        }
      });
      [...indexesToRemove]
        .reverse()
        .forEach((indx) => this.mentionedUsers.splice(indx, 1));
    },
    async handleInputKeyDown(event) {
      const key = event.key;
      if (this.autocompleteVisible) {
        if (key === "ArrowDown") {
          if (
            this.autocompleteSelection <
            this.autocompleteCurrentUsers.length - 1
          ) {
            this.autocompleteSelection++;
          }
          event.preventDefault();
          return;
        }
        if (key === "ArrowUp") {
          if (this.autocompleteSelection > 0) {
            this.autocompleteSelection--;
          }
          event.preventDefault();
          return;
        }
        if (key === "Enter" || key === " ") {
          if (
            this.autocompleteSelection !== -1 &&
            this.autocompleteCurrentUsers.length > 0
          ) {
            await this.selectMentionedUser({
              ...this.autocompleteCurrentUsers[this.autocompleteSelection],
            });
          }
          event.preventDefault();
          return;
        }
      }
      if (key === "Backspace") {
        this.ifMentionThenRemove();
      }
    },
    async handleKeyUp(event) {
      await this.getAutocomplete(event);
    },
    async getAutocomplete(event) {
      const key = event.key;
      if (this.autocompleteVisible === false) {
        if (key === "@") {
          if (this.autocompleteUsers.length > 0) {
            this.autocompleteSelection = this.autocompleteUsers.length - 1;
          }
          this.autocompleteVisible = true;
          this.autocompleteInput = "";
        }
        return;
      }

      if (key === "Backspace") {
        if (this.autocompleteInput.length === 0) {
          this.autocompleteVisible = false;
          return;
        }
        if (this.autocompleteInput.length === 1) {
          this.autocompleteUsers = [];
        }
        this.autocompleteInput = this.autocompleteInput.substring(
          0,
          this.autocompleteInput.length - 1
        );
        if (this.autocompleteInput.length > 0) {
          await this.fetchAutocomplete();
        }
      }

      if (key.length === 1) {
        this.autocompleteInput += key;
        await this.fetchAutocomplete();
      }

      if (this.autocompleteInput.length === 0) {
        this.autocompleteCurrentUsers = this.autocompleteUsers.filter(
          (user) =>
            !this.mentionedUsers.map((mUser) => mUser.id).includes(user.id)
        );

        await this.resizeAutocompleteList();
        return;
      }

      this.autocompleteCurrentUsers = this.autocompleteUsers.filter(
        (user) =>
          !this.mentionedUsers.map((mUser) => mUser.id).includes(user.id)
      );

      if (key !== "ArrowUp" && key !== "ArrowDown") {
        this.autocompleteSelection = this.autocompleteCurrentUsers.length - 1;
      }

      await this.resizeAutocompleteList();
    },
    async selectMentionedUser(user) {
      this.autocompleteVisible = false;
      this.mentionedUsers.push(user);
      const [start, end] = [
        this.textInput.selectionStart,
        this.textInput.selectionEnd,
      ];
      const mention = `@${user.name}`;
      this.textInput.setRangeText(
        mention,
        start - this.autocompleteInput.length - 1,
        end
      );
      this.textInput.setRangeText(
        ` `,
        start + mention.length,
        start + mention.length + 1
      );
      this.autocompleteInput = "";
      const inputContent = this.textInput.value.length;
      this.textInput.focus();
      this.textInput.setSelectionRange(inputContent, inputContent);
    },
    ifMentionThenRemove() {
      let wordStart = -1;
      let wordEnd = -1;
      const currentPositionStart = this.textInput.selectionStart;
      const currentPositionEnd = this.textInput.selectionEnd;

      const input = this.textInput.value;
      for (let i = currentPositionStart; i >= 0; i--) {
        if (input[i] === " ") {
          return;
        }
        if (input[i] === "@") {
          wordStart = i;
          break;
        }
      }
      if (currentPositionEnd === input.length) {
        wordEnd = input.length;
      } else {
        for (let i = currentPositionEnd; i < input.length; i++) {
          if (input[i] === " ") {
            wordEnd = i;
            break;
          }
        }
      }

      const mentionMaybe =
        wordEnd === -1
          ? this.textInput.value.substring(wordStart)
          : this.textInput.value.substring(wordStart, wordEnd);

      const isMention = this.mentionedUsers.findIndex(
        (user) => user.name === mentionMaybe.substring(1)
      );
      if (isMention === -1) {
        return;
      }

      this.mentionedUsers.splice(isMention, 1);

      if (wordEnd === -1) {
        this.textInput.value = this.textInput.value.substring(0, wordStart);
      } else {
        this.textInput.value =
          this.textInput.value.substring(0, wordStart) +
          this.textInput.value.substring(wordEnd);
      }
    },
    async resizeAutocompleteList() {
      await sleep(10);
      const autocompleteList = el.querySelector("#autocomplete_list");
      const autocompleteListEntryHeight = el.querySelector(
        ".autocomplete_list_item"
      )?.offsetHeight;

      if (this.autocompleteCurrentUsers.length === 0) {
        autocompleteList.style.height = "auto";
        return;
      }

      const mentionsListHeight =
        autocompleteListEntryHeight * this.autocompleteCurrentUsers.length;

      if (
        autocompleteListEntryHeight &&
        mentionsListHeight > 0.6 * document.documentElement.scrollHeight
      ) {
        autocompleteList.style.height = `${
          0.6 * document.documentElement.scrollHeight
        }px`;
        return;
      }

      autocompleteList.style.height = `${mentionsListHeight + 20}px`;
    },
    async fetchAutocomplete() {
      this.fetchingUsers = true;
      await fetch(
        `/chat/user_autocomplete/${this.chat_id}?q=${this.autocompleteInput}`
      )
        .then((res) => res.json())
        .then((users) => {
          this.autocompleteUsers = users.map((user) => ({
            id: user.id,
            name: user.handle,
          }));
          this.fetchingUsers = false;
        })
        .catch(() => {
          this.fetchingUsers = false;
        });
    },
  };
};
