import Alpine from "alpinejs";

Alpine.data("fetcher", ({ type = "json", url = undefined } = {}) => ({
  type,
  /* data returned by the endpoint (if response is ok) */
  data: null,
  loading: false,
  statusCode: null,
  /**
   * Error data if available (if the response failed).
   * Do not relay on this to check if request failed as it may not always
   * have data associated with it. Use `hasFailed` instead.
   */
  errorData: null,
  /**
   * If thee request was successful.
   */
  hasSucceeded: false,
  /**
   * If the request has failed for any reason.
   */
  hasFailed: false,
  /**
   * If the response has finished loading (ok or error).
   */
  hasFinished: false,

  init() {
    if (url != null) this.load(url);
  },

  reset() {
    this.errorData = this.data = this.statusCode = null;
    this.loading =
      this.hasFailed =
      this.hasFinished =
      this.hasSucceeded =
        false;
  },

  async load(url, { json, csrfToken } = {}) {
    this.reset();
    this.loading = true;

    try {
      let headers = {
        "X-Requested-With": "XMLHttpRequest",
      };
      let init = { headers };

      if (type === "json") headers["Accept"] = "application/json";
      if (csrfToken != null) headers["X-CSRFToken"] = csrfToken;
      if (json !== undefined) {
        init["body"] = JSON.stringify(json);
        init.headers["Content-Type"] = "application/json";
        init.method = "post";
      }

      const resp = await fetch(url, init);
      if (resp.ok) {
        if (type === "json") this.data = await resp.json();
        else this.data = await resp.text();
        this.hasSucceeded = true;
      } else {
        this.hasFailed = true;
        const ct = resp.headers.get("Content-Type") || "";
        if (ct.toLowerCase().indexOf("application/json") !== -1) {
          this.errorData = await resp.json();
        }
      }
    } finally {
      this.loading = false;
    }
  },
}));
