const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const chatReply = {
  replyToPost: null,
  overlay: document.querySelector("#chat-loading-overlay"),
  replyShorten(reply) {
    if (reply.length > 40) {
      return `${reply.substring(0, 40)}...`;
    }
    return reply;
  },
  showRepliesOverlay() {
    const postsWindow = document.querySelector("#chat-posts-window");
    this.overlay.style.top = postsWindow.top;
    this.overlay.style.left = postsWindow.left;
    this.overlay.style.width = `${postsWindow.scrollWidth}px`;
    this.overlay.style.height = `${postsWindow.offsetHeight}px`;
    this.overlay.classList.add("visible");
  },
  hideRepliesOverlay() {
    this.overlay.classList.remove("visible");
  },
  reply() {
    this.replyToPost = { ...this.contextMenuParams.post };
    this.textInput.focus();
    this.textInput.select();
    this.hideMenuContext();
  },
  async getRepliedPost(postId) {
    this.showRepliesOverlay();
    await postContent(
      `/chat/replied_post/${this.chat_id}/${postId}`,
      null,
      this.itemsContainer,
      () => {
        this.hideRepliesOverlay();
      },
      "replaceChildren",
      () => {
        this.hideRepliesOverlay();
      }
    );
  },

  async scrollToPost(postId) {
    const endOfAppName = this.currentApp.indexOf(" -");
    const parsedApp = this.currentApp.substring(0, endOfAppName);
    await this.getRepliedPost(postId);
    await sleep(500);
    const post = document.getElementById(`post_${postId}`);
    post.scrollIntoView({ behavior: "smooth" });
    const postOverlay = post.querySelector(".highlighted-post-after");
    postOverlay.classList.add("highlighted-post-after-animate");
    postOverlay.style.opacity = "0";
    await sleep(3000);
    postOverlay.remove();
  },
};
