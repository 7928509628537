import Alpine from "alpinejs";
import { formatAddressDisplay } from "../utils";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

Alpine.data("execWeb3Call", (params) => ({
  done: false,
  error: "",
  inProgress: false,
  progressText: "",
  txHash: null,

  get txExplorerUrl() {
    if (this.txHash == null) return "";
    const { config } = this.$store;

    return `${config["etherscan"]}/tx/${this.txHash}`;
  },

  get isConnected() {
    return this.$store.web3.isConnected;
  },

  async start() {
    if (this.inProgress) return;

    this.inProgress = true;
    this.progressText = "";
    this.error = "";
    this.txHash = null;

    try {
      await this._run();
    } catch (err) {
      this.error =
        err?.error?.message || err.reason || err.message || err.toString();
      return;
    } finally {
      this.inProgress = false;
      this.progressText = "";
    }
    this.done = true;
  },

  async _run() {
    const { web3, config } = this.$store;
    const { mintChainId } = config;
    const {
      contractAddress,
      functionAbi,
      callArgs,
      address,
      readyProbe,
      value,
    } = params;

    if (!web3.isConnected) {
      throw new Error("Please connect your wallet.");
    }

    if (web3.chainId !== mintChainId) {
      throw new Error("Please switch to Ethereum Mainnet first.");
    }

    if (web3.address.toLowerCase() !== address.toLowerCase()) {
      throw new Error(
        `Please switch to ${formatAddressDisplay(address)} wallet.`
      );
    }

    const w3 = new Web3(web3.provider);
    const fnName = functionAbi["name"];
    const contract = new w3.eth.Contract([functionAbi], contractAddress);
    const meth = contract.methods[fnName];

    this.progressText = "Waiting for transaction approval ...";
    const tx = meth.apply(null, callArgs).send({ from: address, value });

    tx.on("transactionHash", (hash) => {
      this.txHash = hash;
      this.progressText = "Waiting for the transaction to be mined ...";
    });

    await tx;

    if (readyProbe) {
      this.progressText = "Waiting for the server ...";

      for (let i = 0; i < 30; i++) {
        await sleep(2000);
        const resp = await fetch(
          readyProbe +
            "?" +
            new URLSearchParams({ tx: this.txHash }).toString(),
          { method: "GET" }
        );
        if (resp.ok) {
          const data = await resp.json();
          if (data.ready) break;
        }
      }
    }
  },
}));
