import Alpine from "alpinejs";

Alpine.data("nftGate", (assets, slug) => ({
  allAssets: assets,
  visibleAssets: [],
  isFabric: true,
  async init() {
    console.debug(slug);
    this.visibleAssets = this.allAssets.slice(0, 3);
    this.isFabric = this.allAssets
      .map((asset) => asset.name.toLowerCase())
      .includes("$fabric");
  },
}));
