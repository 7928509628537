import Alpine from "alpinejs";

Alpine.data("siteRoles", () => ({
  siteRoles: document.querySelectorAll("[data-filter-name]"),
  invisibleClassName: "site-roles_invisible",
  searchPhrase: "",
  init() {
    this.searchPhrase = "";
  },
  filterSites() {
    if (this.searchPhrase.length === 0) {
      this.siteRoles.forEach((item) =>
        item.classList.remove(this.invisibleClassName)
      );
      return;
    }
    this.siteRoles.forEach((item) => {
      if (
        item.attributes["data-filter-name"].value
          .toLowerCase()
          .search(this.searchPhrase) === -1
      ) {
        item.classList.add(this.invisibleClassName);
      }
    });
  },
  formatRequiredTokens(amount) {
    const message = " tokens to enter";
    if (amount < 1000) {
      return amount + message;
    }
    return amount / 1000 + "k" + message;
  },
}));
