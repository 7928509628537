import { autorun, makeAutoObservable, observable, action } from "mobx";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { mainnet } from "@wagmi/core/chains";
import {
  configureChains,
  createConfig,
  getAccount,
  watchAccount,
  disconnect,
  //  connect,
  //  getWalletClient,
  //  getPublicClient
} from "@wagmi/core";

class Web3Store {
  backend = null;
  w3m = null;
  address = "";
  chainId = null;
  _isConnected = false;

  constructor() {
    makeAutoObservable(this, { w3m: observable.ref, backend: observable.ref });

    autorun(async () => {
      const { address, backend } = this;
    });
  }
  setup() {
    const chains = [mainnet];
    const projectId = "8eeb522a6ddb5ef806c7e76df10138a9";

    const { publicClient } = configureChains(chains, [
      w3mProvider({ projectId }),
    ]);
    this.publicClient = publicClient;
    const wagmiConfig = createConfig({
      autoConnect: false,
      connectors: w3mConnectors({ projectId, chains }),
      publicClient,
    });
    this.wagmiConfig = wagmiConfig;
    this.ethereumClient = new EthereumClient(wagmiConfig, chains);
    watchAccount(async (newState) => {
      console.log("watch", newState);
      console.log("this", this);
      this._isConnected = newState.isConnected;

      if (newState.isConnected) {
        this.address = newState.address;
        this.backend = newState.connector;
        this.chainId = await newState.connector.getChainId();
      } else {
        this.address = "";
        this.backend = null;
        this.chainId = null;
      }
      //console.log('postWatch')
      /*
      if (newState.isConnected == true) {
        const signature = await signMessage({
          message: 'gm wagmi frens',
        })
      }
      */
    });
    const web3modal = new Web3Modal(
      { projectId: projectId },
      this.ethereumClient
    );

    window.w3m = this.w3m = web3modal;
    this.unsubscribeModal = this.w3m.subscribeModal((newState) => {
      //console.log(newState)
      if (newState.open == false) {
      }
    });

    this.unsubscribeEvents = this.w3m.subscribeEvents((newState) => {
      //console.log(newState)
    });
  }

  onChainChanged = (chainId) => {
    // different wallets send different values, eg sometimes it's 1, sometimes it's "0x1" or "0x01" etc.
    const parsedId = parseInt(chainId);
    console.debug("onChainChanged", parsedId);
    this.chainId = parsedId;
  };

  onW3MConnected = async (provider) => {
    if (Object.is(this.backend, provider)) {
      // bail out early if provider has not changed
      return;
    }

    console.debug("w3m connected", provider);
    provider.on("accountsChanged", this.onAccountChanged);
    provider.on("chainChanged", this.onChainChanged);
    provider.on("disconnect", this.onW3MDisconnected);
    const addresses = await provider.request({ method: "eth_accounts" });
    const chainId = await provider.request({ method: "eth_chainId" });

    this.backend = provider;
    this.address = addresses[0];
    this.chainId = parseInt(chainId);
  };

  onW3MDisconnected = (provider) => {
    console.debug("w3m disconnected", provider);
  };

  setupModal = async () => {
    this.setup();
    if (this.w3m == null) {
      this.setup();
    }
    const account = await getAccount(this.publicClient);
    //console.log("acc",account);

    this._isConnected = account.isConnected;
  };

  /**
   * Will connect to the provider if one is already selected by the user.
   * Use this when page is loaded.
   */
  softConnect = () => {
    this.setupModal();
    this.w3m.toggleModal(); // this won't actually show the modal
  };

  /**
   * This will force UI for selecting a wallet.
   */
  changeWallet = async () => {
    this.setupModal();

    try {
      await this.w3m.connect();
    } catch (err) {
      if (err === "Modal closed by user") {
        prev != null && this.w3m.setCachedProvider(prev);
        return;
      }
      throw err;
    }
  };

  connect = async () => {
    this.setupModal();

    if (!this.isConnected) {
      await this.w3m.openModal();
    }
  };

  get isConnected() {
    return this.backend != null && this._isConnected;
  }

  disconnect = async () => {
    await disconnect();
    this._isConnected = false;
  };
}

const web3store = new Web3Store();

export { Web3Store };
export default web3store;
