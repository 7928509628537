import escapeHtml from "escape-html";
const RE_WALLET_ADDRESS = /0x[0-9a-f]{40}/i;

export function isAnyAddress(value) {
  return typeof value === "string" && value.match(RE_WALLET_ADDRESS);
}

export async function jsonPost(url, data) {
  const resp = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  // MAYBE: invalid should be an exception
  if (resp.ok) {
    return { type: "ok", data: await resp.json() };
  } else if (resp.status === 400) {
    return { type: "invalid", errors: await resp.json() };
  } else {
    throw new Error(`HTTP Error ${resp.status}`);
  }
}

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (const element of cookies) {
      const cookie = element.trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
export const csrftoken = getCookie("csrftoken");

window.fetchContent = (form, targetSelector) => {
  const form_data = new FormData(form);
  const form_str = new URLSearchParams(form_data).toString();
  const url = "?" + form_str;
  fetch(url)
    .then((response) => {
      return response.text();
    })
    .then(function (txt) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(txt, "text/html");
      document
        .querySelector(targetSelector)
        .replaceWith(doc.querySelector(targetSelector));
    });
};

window.loadList = (el) => {
  const form = el.closest("form");
  if (el.dataset.page) {
    const page_input = form.querySelector("input#page");
    if (page_input) {
      page_input.value = el.dataset.page;
    }
  }

  if (form.dataset.selector) {
    fetchContent(form, form.dataset.selector);
  } else {
    console.error("No selector provided");
  }
};

window.postContent = async (
  url,
  data,
  targetEl = null,
  callback = null,
  method = "appendChild",
  errCallback = null
) => {
  return fetch(url, {
    method: "post",
    body: data,
    headers: { "X-CSRFToken": csrftoken },
    mode: "same-origin", // Do not send CSRF token to another domain.
  })
    .then(async (response) => {
      if (response.ok) {
        return response.text();
      }
      if (errCallback) errCallback(response);
    })
    .then(function (txt) {
      if (!txt) return;

      if (targetEl) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(txt, "text/html");
        const newEl = doc.querySelector("body").firstChild;
        targetEl[method](newEl);
      }
      if (callback) callback();
    });
};

window.postForm = async (
  formEl,
  targetEl,
  callback = null,
  method = "append",
  errCallback = null,
  params = []
) => {
  const data = new FormData();
  const tmpData = new FormData(formEl);
  for (const entry of tmpData.entries()) {
    data.append(entry[0], entry[1]);
  }
  data.set("body", escapeHtml(tmpData.get("body")));
  params.forEach((param) => {
    data.append(param.name, param.value);
  });
  postContent(
    formEl.getAttribute("action"),
    data,
    targetEl,
    function () {
      if (callback) callback();
      formEl.reset();
    },
    method,
    errCallback
  );
};

export function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function formatBalance(balance, digits = 0) {
  const decimals = 18;
  const s = balance.toString().padStart(18, "0");
  const point = s.length - decimals;
  let a = s.substring(0, point);
  const b = s.substring(point, point + digits);
  if (a.length === 0) a = "0";
  if (b.length) return `${a}.${b}`;
  return a.toString();
}

export async function logPromiseError(promise) {
  try {
    return await promise;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export function formatAddressDisplay(address) {
  if (!isAnyAddress(address)) {
    return address;
  }

  return (
    address.substring(0, 6) + "..." + address.substring(address.length - 4)
  );
}

const QRCode = require("qrcode");

window.generateQRCode = function (targetEl, uri) {
  const canvas = targetEl;

  QRCode.toCanvas(canvas, uri, { width: 350 }, function (error) {
    if (error) console.error(error);
  });
};
