/**
 * Alpine.js web3 store.
 */

import Alpine from "alpinejs";
import { autorun } from "mobx";

import web3store from "../stores/web3-store";
import { formatBalance, formatAddressDisplay } from "../utils";

/* for now it adapts mobx web3 store, should become its own store when we move away from preact + mobx */
Alpine.store("web3", {
  isConnected: false,
  address: null,
  chainId: null,
  provider: null,
  ethBalance: null,

  init() {
    autorun(() => {
      this.isConnected = web3store.isConnected;
      this.chainId = web3store.chainId;
      this.address = web3store.address;
      this.provider = web3store.backend;
      this.ethBalance = web3store.ethBalance;
    });
  },

  connect() {
    web3store.connect();
  },

  disconnect() {
    web3store.disconnect();
  },

  get shortAddress() {
    return formatAddressDisplay(this.address);
  },

  get ethBalanceDisplay() {
    return this.ethBalance != null
      ? `${formatBalance(this.ethBalance)} ETH`
      : "";
  },
});
